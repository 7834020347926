.notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  background-color: rgba(100, 100, 100, 0.5);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .space {
    min-height: 2vw;

    @media only screen and (max-width: 768px) {
      min-height: 8vw;
    }
  }

  .modal {
    background-color: white;
    width: 56vw;
    min-height: 48vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 768px) {
      width: 80vw;
      min-height: 164vw;
    }

    &__title {
      font-family: "Libre Caslon Text";
      text-align: center;
      font-size: 1.7vw;
      margin-top: 3.6vw;
      font-weight: 500;
      color: #093829;

      @media only screen and (max-width: 768px) {
        font-size: 4.2vw;
        padding: 0 8vw;
        margin-top: 6vw;
      }
    }

    &__content {
      margin-top: 2.4vw;
      display: flex;
      flex-wrap: wrap;
      width: 30vw;
      row-gap: 2.3vw;
      justify-content: space-between;

      @media only screen and (max-width: 768px) {
        margin-top: 4vw;
        flex-direction: column;
        align-items: center;
        text-align: center;
        row-gap: 3vw;
        width: 100%;
      }

      > * {
        width: 42%;

        @media only screen and (max-width: 768px) {
          width: 52vw;
        }
      }

      .remarks {
        width: 100%;

        @media only screen and (max-width: 768px) {
          width: 52vw;
        }

        .value {
          max-height: 6vw;
          overflow-y: scroll;
          text-align: left;
          overflow-wrap: break-word;
          white-space: pre-line;
          word-wrap: break-word;

          @media only screen and (max-width: 768px) {
            max-height: 14vw;
          }
        }
      }

      .label {
        font-family: "Open Sans";
        font-weight: 300;
        color: #093829;
        font-size: 1.3vw;

        @media only screen and (max-width: 768px) {
          font-size: 3vw;
        }
      }

      .value {
        margin-top: 0.3vw;
        font-family: "Open Sans";
        font-weight: 500;
        color: #093829;
        font-size: 1.5vw;
        overflow: scroll;
        white-space: nowrap;

        @media only screen and (max-width: 768px) {
          margin-top: 1vw;
          font-size: 3.6vw;
        }
      }
    }

    &__divider {
      border-top: 0.1vw solid #0d3b2b;
      height: min-content;
      width: 100%;
    }

    &__btn-group {
      margin: 3vw 0;
      display: flex;
      column-gap: 2.4vw;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 3.6vw;
        margin: 8vw 0;
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Libre Caslon Text";
        width: 17.6vw;
        padding: 0.8vw 0;
        font-size: 1.3vw;
        cursor: pointer;

        @media only screen and (max-width: 768px) {
          padding: 2.4vw 0;
          font-size: 3vw;
          width: 44vw;
        }

        &--confirm {
          background-color: #093829;
          color: white;
        }

        &--cancel {
          border: solid 1px #0d3b2b;
          color: #0d3b2b;
        }
      }
    }
  }
}
