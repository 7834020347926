.main {
  input[type="text"] {
    border: none;
    border-bottom: 1px solid #093829;
    outline: none;
    background-color: transparent;
    font-family: "Open Sans";
    font-weight: 500;
    padding: 0.25vw 0;
  }

  input[type="radio"] {
    appearance: none;
    margin: 0;
    width: 0.8vw;
    height: 0.8vw;
    border: 0.08vw solid #093829;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      width: 3vw;
      height: 3vw;
      border: 0.12vw solid #093829;
    }
  }

  input[type="radio"]::before {
    content: "";
    width: 0.5vw;
    height: 0.5vw;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: #093829;

    @media only screen and (max-width: 768px) {
      width: 1.5vw;
      height: 1.5vw;
    }
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  ::-webkit-scrollbar {
    display: none;
    // width: 0.1vw;
  }
  // ::-webkit-scrollbar-track {
  //   background: #ebe4d7;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background: #888;
  // }
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #555;
  // }

  &__language {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    margin-right: 8vw;
    margin-top: 4.6vw;
    font-family: "Open Sans";
    color: #ebe4d7;
    font-size: 1vw;
    display: flex;
    line-height: 1;
    align-items: center;
    text-wrap: nowrap;

    @media only screen and (max-width: 768px) {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-right: 0;
      margin-top: 9vw;
      font-size: 3.4vw;
      width: min-content;
    }

    &__logo {
      margin-right: 0.8vw;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.2vw;

      @media only screen and (max-width: 768px) {
        margin-right: 2.6vw;
        width: 4vw;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__divider {
      border-right: 1.5px solid #ebe4d7;
      margin: 0 0.6vw;
      height: 1.2vw;

      @media only screen and (max-width: 768px) {
        height: 4vw;
        margin: 0 2.1vw;
      }
    }

    &__english {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 0.1vw;
        text-underline-offset: 0.3vw;

        @media only screen and (max-width: 768px) {
          text-decoration-thickness: 0.3vw;
          text-underline-offset: 1.2vw;
        }
      }
    }

    &__chinese {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 0.1vw;
        text-underline-offset: 0.3vw;

        @media only screen and (max-width: 768px) {
          text-decoration-thickness: 0.3vw;
          text-underline-offset: 1.2vw;
        }
      }
    }
  }

  &__top {
    position: relative;

    &__bg-img {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      @media only screen and (max-width: 768px) {
        width: 100%;
        padding-top: 3.2vw;
        align-items: center;
      }

      > * {
        padding-left: 11vw;

        @media only screen and (max-width: 768px) {
          padding-left: 0;
        }
      }

      &__logo {
        padding-left: 9vw;
        width: 23vw;

        @media only screen and (max-width: 768px) {
          padding-left: 0;
          width: 58vw;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__welcome {
        margin-top: 5vw;
        font-family: "Libre Caslon Text";
        font-size: 3vw;
        font-weight: 500;
        color: #ebe4d7;
        margin-bottom: 0.8vw;

        @media only screen and (max-width: 768px) {
          margin-top: 10vw;
          font-size: 7vw;
          margin-bottom: 2vw;
        }
      }

      &__sentence {
        padding-top: 1.6vw;
        padding-bottom: 2.2vw;
        font-family: "Open Sans";
        font-weight: 300;
        font-size: 1.6vw;
        color: #ebe4d7;
        white-space: pre-wrap;

        @media only screen and (max-width: 768px) {
          font-size: 3.8vw;
          text-align: center;
          padding-top: 3.4vw;
          padding-bottom: 4.5vw;
          line-height: 1.1;
        }
      }

      &__divider {
        width: 21vw;

        @media only screen and (max-width: 768px) {
          width: 50vw;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__btn-group {
        display: flex;
        column-gap: 2.5vw;

        @media only screen and (max-width: 768px) {
          column-gap: 7vw;
          margin-top: 1vw;
        }

        div {
          font-size: 2vw;
          font-family: "Libre Caslon Text";
          color: #ebe4d7;
          cursor: pointer;

          @media only screen and (max-width: 768px) {
            font-size: 5vw;
          }

          &:hover {
            text-decoration: underline;
            text-decoration-thickness: 0.1vw;
            text-underline-offset: 0.5vw;

            @media only screen and (max-width: 768px) {
              text-decoration-thickness: 0.3vw;
              text-underline-offset: 1.2vw;
            }
          }
        }
      }
    }
  }

  &__rsvp {
    display: flex;
    flex-direction: row;
    height: 64.2vw;
    overflow: hidden;
    align-items: center;
    padding: 0 4vw;

    @media only screen and (max-width: 768px) {
      flex-direction: column-reverse;
      height: unset;
      padding: 0;
    }

    &__img {
      flex: 1;

      @media only screen and (max-width: 768px) {
        flex: unset;
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__form {
      flex: 1.2474;
      position: relative;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 768px) {
        flex: unset;
      }

      &__bg-img {
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      &__content {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__title {
          font-family: "Libre Caslon Text";
          font-size: 2vw;
          font-weight: 500;
          color: #093829;
          display: flex;
          column-gap: 3.8vw;

          @media only screen and (max-width: 768px) {
            font-size: 5.8vw;
            flex-direction: column;
            text-align: center;
          }

          .rsvp {
            font-size: 3vw;
            text-wrap: nowrap;

            @media only screen and (max-width: 768px) {
              font-size: 8vw;
            }
          }

          span {
            font-size: 1.6vw;
            font-family: "Open Sans";
            font-weight: 300;
            color: #093829;

            @media only screen and (max-width: 768px) {
              margin-top: 3vw;
              font-size: 4.2vw;
            }
          }
        }

        &__form {
          margin-top: 3.8vw;

          @media only screen and (max-width: 768px) {
            margin-top: 3vw;
            padding: 0 3vw;
          }

          .grid {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2.4vw 6vw;

            @media only screen and (max-width: 768px) {
              display: flex;
              flex-wrap: wrap;
              row-gap: 5vw;
            }

            .form-field {
              display: flex;
              flex-direction: column;
              row-gap: 0.4vw;

              @media only screen and (max-width: 768px) {
                width: 100%;
                row-gap: 0.8vw;
              }

              label {
                font-family: "Open Sans";
                font-weight: 300;
                font-size: 1vw;
                color: #093829;

                @media only screen and (max-width: 768px) {
                  font-size: 3.2vw;
                }
              }

              input {
                font-size: 1vw;
                color: #093829;

                @media only screen and (max-width: 768px) {
                  font-size: 3.6vw;
                }
              }
            }

            .form-field-alt {
              display: flex;
              flex-direction: column;
              row-gap: 1vw;

              @media only screen and (max-width: 768px) {
                width: 80%;
                row-gap: 2vw;
              }

              label {
                font-family: "Open Sans";
                font-weight: 300;
                font-size: 1vw;
                color: #093829;

                @media only screen and (max-width: 768px) {
                  font-size: 3.2vw;
                }
              }

              .radio-group {
                display: flex;
                column-gap: 1.8vw;

                @media only screen and (max-width: 768px) {
                  column-gap: 5vw;
                }

                &__btn {
                  display: flex;
                  align-items: center;
                  column-gap: 0.6vw;

                  @media only screen and (max-width: 768px) {
                    column-gap: 1.8vw;
                  }

                  label {
                    font-family: "Open Sans";
                    color: #093829;
                    font-size: 0.9vw;
                    font-weight: 500;

                    @media only screen and (max-width: 768px) {
                      font-size: 3vw;
                    }
                  }
                }
              }
            }

            .form-field-tap {
              display: flex;
              flex-direction: column;
              row-gap: 0.3vw;

              label {
                font-family: "Open Sans";
                font-weight: 300;
                font-size: 1vw;
                color: #093829;

                @media only screen and (max-width: 768px) {
                  font-size: 3.2vw;
                }
              }

              &__content {
                margin-top: 0.2vw;
                height: 2vw;
                width: 6vw;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #093829;
                padding-bottom: 0.2vw;

                @media only screen and (max-width: 768px) {
                  height: 6vw;
                  width: 14vw;
                }

                .minus-btn {
                  width: 1.3vw;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;

                  @media only screen and (max-width: 768px) {
                    width: 3vw;
                  }

                  img {
                    width: 100%;
                    height: auto;
                  }
                }

                .plus-btn {
                  width: 1.3vw;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;

                  @media only screen and (max-width: 768px) {
                    width: 3vw;
                  }

                  img {
                    width: 100%;
                    height: auto;
                  }
                }

                .magnitude {
                  font-size: 1.1vw;
                  font-weight: 500;
                  font-family: "Open Sans";
                  color: #093829;
                  cursor: default;

                  @media only screen and (max-width: 768px) {
                    font-size: 3.2vw;
                  }
                }
              }
            }

            .form-field-dropdown {
              display: flex;
              flex-direction: column;
              row-gap: 0.5vw;
              justify-content: center;

              @media only screen and (max-width: 768px) {
                row-gap: 0.2vw;
              }

              label {
                font-family: "Open Sans";
                font-weight: 300;
                font-size: 0.8vw;
                color: #093829;

                @media only screen and (max-width: 768px) {
                  font-size: 1.8vw;
                }
              }
            }
          }

          .form-remarks {
            margin-top: 2vw;
            display: flex;
            flex-direction: column;
            row-gap: 0.3vw;

            @media only screen and (max-width: 768px) {
              margin-top: 5vw;
              row-gap: 1vw;
            }

            label {
              font-family: "Open Sans";
              font-weight: 300;
              font-size: 1vw;
              color: #093829;

              @media only screen and (max-width: 768px) {
                font-size: 3.2vw;
              }
            }

            textarea {
              font-size: 1vw;
              font-family: "Open Sans";
              font-weight: 500;
              color: #093829;
              background-color: transparent;
              border: 1px solid #093829;
              resize: none;
              outline: none;
              padding: 0.3vw 0.5vw;

              @media only screen and (max-width: 768px) {
                font-size: 3.6vw;
                padding: 0.8vw 1.2vw;
              }
            }
          }

          .form-legend {
            margin-top: 0.5vw;
            font-family: "Open Sans";
            font-size: 0.6vw;
            font-weight: 300;
            color: #093829;

            @media only screen and (max-width: 768px) {
              margin-top: 1vw;
              font-size: 2vw;
            }
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          margin-top: 2.8vw;
          row-gap: 2vw;

          @media only screen and (max-width: 768px) {
            margin-top: 12vw;
            row-gap: 4vw;
          }

          > * {
            display: flex;
            flex-direction: row;
            column-gap: 1.5vw;

            @media only screen and (max-width: 768px) {
              column-gap: 3.5vw;
            }
          }

          .info-icon {
            width: 1.2vw;
            display: flex;
            align-items: center;

            @media only screen and (max-width: 768px) {
              width: 4vw;
            }

            img {
              width: 100%;
              height: auto;
            }
          }

          .info-description {
            font-size: 1.6vw;
            line-height: 1.2;
            font-family: "Open Sans";
            font-weight: 300;
            color: #093829;
            white-space: pre-wrap;

            @media only screen and (max-width: 768px) {
              font-size: 4.4vw;
            }
          }
        }

        .submit-btn {
          margin-top: 2vw;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 1px #093829;
          font-family: "Libre Caslon Text";
          color: #093829;
          width: 8vw;
          padding: 0.6vw 0;
          font-size: 1vw;
          cursor: pointer;

          @media only screen and (max-width: 768px) {
            margin: 0 auto;
            margin-top: 6vw;
            font-size: 4vw;
            padding: 2vw 0;
            width: 24vw;
          }

          &.disabled {
            cursor: default;
            color: rgb(170, 170, 170);
            border: solid 1px rgb(170, 170, 170);
          }
        }

        &__sentence {
          margin-top: 3.8vw;
          font-size: 1.6vw;
          font-family: "Open Sans";
          font-weight: 300;
          color: #093829;

          @media only screen and (max-width: 768px) {
            margin-top: 10vw;
            font-size: 4.8vw;
          }
        }

        &__btn-group {
          display: flex;
          flex-direction: row;
          column-gap: 2.5vw;
          margin-top: 3.8vw;

          @media only screen and (max-width: 768px) {
            flex-direction: column;
            row-gap: 7vw;
            align-items: center;
            margin-top: 16vw;
          }

          .form-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px #093829;
            font-family: "Libre Caslon Text";
            color: #093829;
            width: 12vw;
            padding: 0.6vw 0;
            font-size: 1vw;
            cursor: pointer;

            @media only screen and (max-width: 768px) {
              width: 50vw;
              padding: 2.6vw 0;
              font-size: 3.8vw;
            }
          }
        }

        &__reservation {
          margin-top: 4vw;
          display: flex;
          flex-direction: row;
          column-gap: 4vw;

          @media only screen and (max-width: 768px) {
            margin-top: 12vw;
            column-gap: 8vw;
          }

          .table-no {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 0.8vw;

            @media only screen and (max-width: 768px) {
              row-gap: 1.5vw;
            }

            .title {
              font-family: "Open Sans";
              font-weight: 300;
              font-size: 0.7vw;
              color: #093829;
              display: flex;
              flex-direction: row;
              line-height: 0.7;

              @media only screen and (max-width: 768px) {
                font-size: 2vw;
              }

              .info-icon {
                margin-left: 0.2vw;
                width: 0.6vw;
                cursor: pointer;
                height: min-content;

                @media only screen and (max-width: 768px) {
                  display: flex;
                  align-items: start;
                  width: 1.8vw;
                }

                img {
                  width: 100%;
                  height: auto;
                }
              }
            }

            .no {
              font-size: 2.8vw;
              margin-left: 0.8vw;

              @media only screen and (max-width: 768px) {
                font-size: 6vw;
                margin-left: 2vw;
              }
            }

            .value {
              font-family: "Open Sans";
              font-weight: 300;
              font-size: 0.5vw;
              color: #093829;

              @media only screen and (max-width: 768px) {
                font-size: 1.5vw;
              }
            }
          }

          .grid {
            flex: 3;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2.6vw 2vw;

            @media only screen and (max-width: 768px) {
              flex: 1;
              display: flex;
              flex-direction: column;
              row-gap: 7vw;
              align-items: center;
              text-align: center;
              width: 100%;
            }

            > * {
              display: flex;
              flex-direction: column;
              row-gap: 0.9vw;
              width: 10vw;

              @media only screen and (max-width: 768px) {
                row-gap: 3vw;
                width: 100%;
              }

              .title {
                font-family: "Open Sans";
                font-weight: 500;
                font-size: 1vw;
                color: #093829;
                line-height: 0.8;

                @media only screen and (max-width: 768px) {
                  font-size: 4vw;
                }
              }

              .value {
                font-family: "Open Sans";
                font-weight: 300;
                font-size: 1.2vw;
                line-height: 1.1;
                color: #093829;
                overflow: scroll;
                white-space: nowrap;

                @media only screen and (max-width: 768px) {
                  font-size: 4.4vw;
                }
              }

              .remarks {
                min-height: 1.5vw;
                max-height: 4vw;
                overflow-y: scroll;
                overflow-wrap: break-word;
                white-space: pre-line;
                word-wrap: break-word;
                width: 32vw;

                @media only screen and (max-width: 768px) {
                  min-height: 6vw;
                  max-height: 19vw;
                  width: 100%;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }

  &__countdown {
    background-color: #093829;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5.4vw 0;
    color: #ebe4d8;
    row-gap: 3vw;

    @media only screen and (max-width: 768px) {
      padding: 10vw 0;
      row-gap: 7vw;
    }

    &__title {
      font-size: 1.8vw;
      font-family: "Libre Caslon Text";
      font-weight: 500;

      @media only screen and (max-width: 768px) {
        font-size: 4.8vw;
      }
    }
  }

  &__wedding-hall {
    &__img {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__event-details {
    display: flex;
    flex-direction: column;
    background-color: #ebe4d8;
    align-items: center;
    padding-bottom: 4vw;

    @media only screen and (max-width: 768px) {
      padding-bottom: 15vw;
    }

    &__title {
      font-family: "Libre Caslon Text";
      font-size: 3vw;
      font-weight: 500;
      color: #0d3b2b;
      -webkit-text-stroke: 1px #0b3a2a;
      margin-top: 3.8vw;

      @media only screen and (max-width: 768px) {
        font-size: 5.2vw;
        margin-top: 12vw;
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 4vw;
      width: 80%;
      column-gap: 6vw;
      height: 40vw;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        height: unset;
        margin-top: 10vw;
      }

      .divider {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
        border-right: 0.05vw solid #0d3b2b;

        @media only screen and (max-width: 768px) {
          border-top: 0.05vw solid #0d3b2b;
          width: 100%;
          height: unset;
          border-right: unset;
        }
      }

      .info {
        flex: 1.2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 2.8vw;

        @media only screen and (max-width: 768px) {
          margin-top: 1vw;
          row-gap: 6.6vw;
        }

        > * {
          display: flex;
          flex-direction: row;
          column-gap: 2.3vw;
          padding-left: 3vw;

          @media only screen and (max-width: 768px) {
            column-gap: 8vw;
            padding: 0 5vw;
          }
        }

        &__icon {
          width: 1.4vw;
          display: flex;
          align-items: center;

          @media only screen and (max-width: 768px) {
            width: 4vw;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        &__description {
          font-size: 1.8vw;
          line-height: 1.2;
          font-family: "Libre Caslon Text";
          font-weight: 500;
          color: #0d3b2b;
          white-space: pre-wrap;

          @media only screen and (max-width: 768px) {
            font-size: 4.2vw;
          }

          .address {
            margin-top: 0.4vw;
            font-size: 1.1vw;
            line-height: 1.3;
            font-family: "Open Sans";
            font-weight: 300;

            @media only screen and (max-width: 768px) {
              margin-top: 1vw;
              font-size: 2.7vw;
            }
          }
        }
      }

      .agenda-img {
        &--en {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: 768px) {
            margin-top: 24vw;
          }

          img {
            width: 56%;
            height: auto;

            @media only screen and (max-width: 768px) {
              width: 66%;
            }
          }
        }

        &--cn {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: 768px) {
            margin-top: 26vw;
          }

          img {
            width: 86%;
            height: auto;

            @media only screen and (max-width: 768px) {
              width: 90%;
            }
          }
        }
      }
    }
  }

  &__seating-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6.8vw 0;
    padding-left: 11vw;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      padding: 0;
      padding-top: 13vw;
      padding-bottom: 9vw;
    }

    &__img {
      width: 40vw;

      @media only screen and (max-width: 768px) {
        width: 68vw;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__description {
      margin-left: 6vw;

      @media only screen and (max-width: 768px) {
        margin: 0;
        margin-top: 12vw;
      }

      .title {
        font-family: "Libre Caslon Text";
        font-size: 3vw;
        font-weight: 500;
        color: #0d3b2b;

        @media only screen and (max-width: 768px) {
          font-size: 7.2vw;
          text-align: center;
        }
      }

      .sentence {
        margin-top: 2.6vw;
        font-family: "Open Sans";
        font-weight: 300;
        font-size: 1.8vw;
        color: #0d3b2b;
        width: 26vw;

        @media only screen and (max-width: 768px) {
          text-align: center;
          margin: 0 auto;
          width: 66vw;
          font-size: 4vw;
          margin-top: 5vw;
        }
      }

      .full-screen {
        margin-top: 6vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        width: min-content;
        white-space: nowrap;

        @media only screen and (max-width: 768px) {
          justify-content: center;
          margin: 0 auto;
          margin-top: 6vw;
        }

        &__img {
          width: 1.7vw;

          @media only screen and (max-width: 768px) {
            width: 4vw;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        &__description {
          font-size: 1.6vw;
          margin-left: 1.3vw;
          font-family: "Open Sans";
          font-weight: 300;
          color: #0d3b2b;

          @media only screen and (max-width: 768px) {
            font-size: 3.5vw;
            margin-left: 3vw;
          }
        }
      }
    }
  }

  &__faq {
    display: flex;
    flex-direction: row;
    background-color: #f7f7f7;

    @media only screen and (max-width: 768px) {
      flex-direction: column-reverse;
      text-align: center;
    }

    &__img {
      flex: 1;

      @media only screen and (max-width: 768px) {
        flex: unset;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__content {
      position: relative;
      flex: 1.6;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 768px) {
        flex: unset;
        align-items: center;
        padding: 0 11vw;
      }

      > * {
        padding-left: 8vw;

        @media only screen and (max-width: 768px) {
          padding-left: unset;
        }
      }

      .title {
        padding-right: 10vw;
        margin-top: 7vw;
        display: flex;
        flex-direction: row;
        font-family: "Libre Caslon Text";
        font-size: 3.4vw;
        font-weight: 500;
        color: #0d3b2b;
        line-height: 1;
        column-gap: 3vw;

        @media only screen and (max-width: 768px) {
          padding-right: unset;
          flex-direction: column;
          margin-top: 8vw;
          font-size: 8vw;
        }

        span {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 1.6vw;
          color: #0d3b2b;
          line-height: 1.2;

          @media only screen and (max-width: 768px) {
            margin-top: 4.8vw;
            font-size: 3.8vw;
          }
        }
      }

      .body {
        margin-top: 5vw;
        padding-right: 7vw;

        @media only screen and (max-width: 768px) {
          padding-right: unset;
          margin-top: 7vw;
          margin-bottom: 5vw;
        }
      }

      .divider {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 54vw;
        height: 4.6vw;
        border-top: 1px solid #0d3b2b;
        padding: 0;

        @media only screen and (max-width: 768px) {
          position: static;
          transform: none;
          width: 85vw;
          margin-top: 5vw;
          height: min-content;
        }
      }

      .whatsapp {
        position: absolute;
        bottom: 1.4vw;
        left: 8vw;
        padding: 0;
        display: flex;
        align-items: center;
        column-gap: 1vw;
        cursor: pointer;

        @media only screen and (max-width: 768px) {
          position: static;
          column-gap: 2.4vw;
          margin: 4vw 0;
        }

        &__img {
          width: 1.5vw;

          @media only screen and (max-width: 768px) {
            width: 4vw;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        &__desc {
          font-family: "Libre Caslon Text";
          font-size: 1.3vw;
          font-weight: 500;
          color: #0d3b2b;
          line-height: 1;

          @media only screen and (max-width: 768px) {
            font-size: 4vw;
          }
        }
      }
    }
  }
}
