@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  padding: 0;

  .web {
    display: block;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
}
