.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    width: 7vw;
    height: 7vw;
    border: 0.3vw solid #0d3b2b;
    border-top: 0.3vw solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @media only screen and (max-width: 768px) {
      width: 20vw;
      height: 20vw;
      border: 0.8vw solid #0d3b2b;
      border-top: 0.8vw solid transparent;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
