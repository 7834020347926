.accordions {
  &__title {
    font-size: 1.6vw;
    font-family: "Libre Caslon Text";
    font-weight: 500;
    color: #0d3b2b;

    @media only screen and (max-width: 768px) {
      margin: 0 auto;
      font-size: 3.8vw;
    }
  }

  &__body {
    font-size: 1.2vw;
    font-family: "Open Sans";
    font-weight: 300;
    color: #0d3b2b;
    line-height: 1.4;

    @media only screen and (max-width: 768px) {
      font-size: 3.3vw;
    }
  }

  &__expand-icon {
    width: 0.3vw;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 768px) {
      width: 0.7vw;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
