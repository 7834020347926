.countdown-timer {
  display: flex;
  text-align: center;
  column-gap: 4vw;

  @media only screen and (max-width: 768px) {
    column-gap: 8vw;
  }

  &__item {
    font-family: "Open Sans";
    display: flex;
    flex-direction: column;
    row-gap: 1vw;

    @media only screen and (max-width: 768px) {
      row-gap: 2vw;
    }

    .value {
      font-weight: bold;
      font-size: 3vw;

      @media only screen and (max-width: 768px) {
        font-size: 8vw;
      }
    }

    .label {
      font-size: 1.4vw;

      @media only screen and (max-width: 768px) {
        font-size: 4vw;
      }
    }
  }
}
