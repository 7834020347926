.login {
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    font-family: "Open Sans";
    font-weight: 300;
    border: none;
    border-bottom: 1px solid #093829;
    outline: none;
    background-color: transparent;
    padding: 0.1vw 0;
  }

  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__language {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    margin-right: 3vw;
    margin-top: 2.4vw;
    font-family: "Open Sans";
    color: #8e9e90;
    font-size: 1vw;
    display: flex;
    line-height: 1;
    align-items: center;
    text-wrap: nowrap;

    @media only screen and (max-width: 768px) {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-right: 0;
      margin-top: 5vw;
      font-size: 3.4vw;
      width: min-content;
    }

    &__logo {
      margin-right: 0.8vw;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.2vw;

      @media only screen and (max-width: 768px) {
        margin-right: 2.6vw;
        width: 4vw;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__divider {
      border-right: 1.5px solid #8e9e90;
      margin: 0 0.6vw;
      height: 1.2vw;

      @media only screen and (max-width: 768px) {
        height: 4vw;
        margin: 0 2.1vw;
      }
    }

    &__english {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 0.1vw;
        text-underline-offset: 0.3vw;

        @media only screen and (max-width: 768px) {
          text-decoration-thickness: 0.3vw;
          text-underline-offset: 1.2vw;
        }
      }
    }

    &__chinese {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 0.1vw;
        text-underline-offset: 0.3vw;

        @media only screen and (max-width: 768px) {
          text-decoration-thickness: 0.3vw;
          text-underline-offset: 1.2vw;
        }
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    margin: 3vw 5.5vw;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin: 8vw 7vw;
    }

    &__img {
      flex: 1.3;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__form {
      flex: 1;
      background-color: white;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 768px) {
        align-items: center;
      }

      > * {
        padding: 0 5vw;
      }

      &__logo {
        width: 19vw;
        margin-top: 5vw;

        @media only screen and (max-width: 768px) {
          margin-top: 5vw;
          width: 30vw;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__main {
        margin-top: 6vw;

        @media only screen and (max-width: 768px) {
          margin-top: 5vw;
          padding-bottom: 8vw;
        }

        &--welcome {
          font-family: "Libre Caslon Text";
          font-size: 3vw;
          font-weight: 500;
          color: #093829;

          @media only screen and (max-width: 768px) {
            text-align: center;
            font-size: 5vw;
          }
        }

        &--title {
          margin-top: 1.1vw;
          font-family: "Open Sans";
          font-weight: 300;
          font-size: 1.8vw;
          line-height: 1.09;
          color: #093829;
          margin-bottom: 1.8vw;

          @media only screen and (max-width: 768px) {
            font-size: 3.8vw;
            text-align: center;
            width: 30vw;
            margin: 0 auto;
            margin-top: 1.5vw;
            margin-bottom: 5vw;
          }
        }

        &--form {
          @media only screen and (max-width: 768px) {
            margin-bottom: 1vw;
          }

          .form-field {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.8vw;

            @media only screen and (max-width: 768px) {
              margin-bottom: 5vw;;
            }

            label {
              font-family: "Open Sans";
              font-weight: 300;
              font-size: 0.8vw;
              color: #093829;
              margin-bottom: 0.4vw;

              @media only screen and (max-width: 768px) {
                font-size: 2.6vw;
              }
            }

            input {
              width: 13vw;
              font-size: 1.1vw;
              color: #093829;

              @media only screen and (max-width: 768px) {
                width: 40vw;
                margin-top: 0.5vw;
                margin-bottom: 1vw;
                font-size: 3.6vw;
              }
            }
          }

          .submit-btn {
            margin-top: 1.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px #093829;
            font-family: "Open Sans";
            color: #093829;
            width: 7.5vw;
            padding: 0.4vw 0;
            font-size: 0.8vw;
            cursor: pointer;

            @media only screen and (max-width: 768px) {
              margin-top: 7.5vw;
              font-size: 2.5vw;
              padding: 1.4vw 0;
              width: 100%;
            }

            &.disabled {
              cursor: default;
              color: rgb(148, 148, 148);
              border: solid 1px rgb(199, 199, 199);
            }
          }
        }

        &--err-msg {
          font-family: "Open Sans";
          margin-top: 0.5vw;
          font-size: 0.8vw;
          color: red;
          line-height: 1;
          height: 0vw;

          @media only screen and (max-width: 768px) {
            font-size: 2.5vw;
            text-align: center;
          }
        }
      }
    }
  }
}
